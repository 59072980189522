import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import WeekIso from 'dayjs/plugin/isoWeek';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import WeekOfYear from 'dayjs/plugin/weekOfYear';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Otovo$Config } from '../types/otovoweb';

dayjs.extend(AdvancedFormat); // use plugin
dayjs.extend(WeekOfYear); // use plugin
dayjs.extend(WeekIso); // use plugin
dayjs.extend(LocalizedFormat); // use plugin
dayjs.extend(relativeTime); // use plugin

export async function setLocale(locale: Otovo$Config['BU_CONFIG']['locale']) {
  switch (locale) {
    case 'de-at':
      await import('dayjs/locale/de-at');
      dayjs.locale('de-at');
      break;
    case 'nb-no':
      await import('dayjs/locale/nb');
      dayjs.locale('nb');
      break;
    case 'sv-se':
      await import('dayjs/locale/sv');
      dayjs.locale('sv');
      break;
    case 'es-es':
      await import('dayjs/locale/es');
      dayjs.locale('es');
      break;
    case 'pt-br':
      await import('dayjs/locale/pt-br');
      dayjs.locale('pt-br');
      break;
    case 'fr-fr':
    case 'fr-be':
      await import('dayjs/locale/fr');
      dayjs.locale('fr');
      break;
    case 'en-gb':
      await import('dayjs/locale/en');
      dayjs.locale('en');
      break;
    case 'it-it':
      await import('dayjs/locale/it');
      dayjs.locale('it');
      break;
    case 'pl-pl':
      await import('dayjs/locale/pl');
      dayjs.locale('pl');
      break;
    case 'de-de':
      await import('dayjs/locale/de');
      dayjs.locale('de');
      break;
    case 'pt-pt':
      await import('dayjs/locale/pt');
      dayjs.locale('pt');
      break;
    case 'nl-nl':
      await import('dayjs/locale/nl');
      dayjs.locale('nl');
      break;
    case 'nl-be':
      await import('dayjs/locale/nl-be');
      dayjs.locale('nl-be');
      break;
    case 'de-ch':
      await import('dayjs/locale/de-ch');
      dayjs.locale('de-ch');
      break;
    case 'fr-ch':
      await import('dayjs/locale/fr-ch');
      dayjs.locale('fr-ch');
      break;
    case 'it-ch':
      await import('dayjs/locale/it-ch');
      dayjs.locale('it-ch');
      break;
    default:
      throw new Error(`Unknown locale ${locale}`);
  }
}

// Ordinal numbers (Do) are not displayed correctly in Sweden or Spain.
// We can remove the conditional expressions if DayJS is fixed or remove
// use of "Do" for Norway.
export const getLongDateFormat = (): string => {
  return dayjs.locale() === 'nb' ? 'dddd Do MMMM' : 'dddd D MMMM';
};

export const getShortDateFormat = (): string => {
  return dayjs.locale() === 'nb' ? 'Do MMMM' : 'D MMMM';
};

export const getWeekdayDayFormat = (): string => {
  return dayjs.locale() === 'nb' ? 'dddd Do' : 'dddd D';
};

export const dateFormat = (
  dateString: string | Date,
  pattern: string = getShortDateFormat(),
): string => {
  const date = dayjs(dateString);
  return date.format(pattern);
};

type LocalizedFormatPatterns = 'LL' | 'L' | 'l' | 'LL LT' | 'L LT' | 'l LT';

/**
 * Formats a date string or Date object into a localized date format.
 * We assume that the locale has been set before calling this function.
 *
 * The default format is 'LL' (e.g. 'December 25, 2020').
 * See a list of available formats here:
 * https://day.js.org/docs/en/display/format#list-of-localized-formats
 *
 * @param {string | Date} dateString - The date string or Date object to format.
 * @param {LocalizedFormatPatterns} [format='LL'] - The localized format pattern to use.
 * @returns {string} The formatted date string.
 */
const getLocalizedDateString = (
  dateString: string | Date,
  format: LocalizedFormatPatterns = 'LL',
): string => {
  return dayjs(dateString).format(format);
};

/**
 * Formats a date string or Date object into a long or short localized date string.
 * The long format used is 'LL' (e.g. 'December 25, 2020' for en-gb).
 * The short format used is 'L' (e.g. '12/25/2020' for en-gb).
 *
 * @param {Date | string} dateString - The date string or Date object to format.
 * @param {'long' | 'short'} formatType - The format type to use ('long' or 'short').
 * @param {boolean} [includeTimeStamp=false] - Whether to include the time stamp in the formatted string.
 * @returns {string} The formatted date string.
 */
export const getDayMonthYearString = (
  dateString: Date | string,
  formatType: 'long' | 'short' = 'long',
  includeTimeStamp: boolean = false,
): string => {
  const format = formatType === 'long' ? 'LL' : 'L';
  if (includeTimeStamp) {
    return getLocalizedDateString(dateString, `${format} LT`);
  }
  return getLocalizedDateString(dateString, format);
};

export default dayjs;
