import generateUUID from './generateUUID';

export const trackingConsentCode = 'C0004%3A1';
const THIRTY_DAYS = 30 * 24 * 60 * 60;

export const cookieEnum = Object.freeze({
  COOKIE_KEY: 'cookie_key',
});

export function getCookieValue(name: string) {
  return document.cookie
    .split('; ')
    .find((row) => row.startsWith(name))
    ?.split('=')[1];
}

export function handleCookieKey(newCookieKey?: string) {
  const { COOKIE_KEY } = cookieEnum;

  if (typeof document === 'undefined' || window.self !== window.top) {
    return;
  }

  const currentCookieKey = getCookieValue(COOKIE_KEY);
  const cookieKey = newCookieKey || currentCookieKey || generateUUID();
  const isNewCookieKeySameAsOld = currentCookieKey === cookieKey;
  const hasValidCookieKey =
    document.cookie.includes(COOKIE_KEY) && !!currentCookieKey;

  if ((hasValidCookieKey && !newCookieKey) || isNewCookieKeySameAsOld) {
    return;
  }

  document.cookie = `${COOKIE_KEY}=${cookieKey}; max-age=${THIRTY_DAYS};`;
}

export function getCookieSearchParams() {
  const cookieSearchparams = new URLSearchParams();
  const { COOKIE_KEY } = cookieEnum;

  if (document.cookie.includes(trackingConsentCode)) {
    cookieSearchparams.append('hasTrackingConsent', 'true');
  }

  if (document.cookie.includes(COOKIE_KEY)) {
    const cookieKey = getCookieValue(COOKIE_KEY);

    cookieSearchparams.append('cookieKey', cookieKey);
  }

  return cookieSearchparams;
}
