// hashUserData.ts

import type { Cloud$Address } from '../../../types/cloudApi';

// Convert input to hex string after SHA-256
async function hashSHA256(input: string): Promise<string> {
  if (!crypto?.subtle?.digest) {
    return ''; // Return an empty string if not supported
  }

  const encoder = new TextEncoder();
  const data = encoder.encode(input);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  return Array.from(new Uint8Array(hashBuffer))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
}

// Normalize input: trim, lowercase
function normalizeInput(input?: string): string {
  return input?.trim().toLowerCase() || '';
}

function splitCustomerName(name?: string): [string, string] {
  if (!name?.trim()) {
    return ['', ''];
  }
  const customerName = name.trim();
  const splitName = customerName.split(/\s+/); // split on any whitespace
  const len = splitName.length;

  if (len >= 2) {
    const lastName = splitName[len - 1];
    const firstName = splitName.slice(0, len - 1).join(' ');
    return [firstName, lastName];
  } else if (len === 1) {
    return [splitName[0], ''];
  } else {
    return ['', ''];
  }
}

/**
 * Hashes user data for Google Tag Manager (GTM) enhanced tracking purposes, ensuring sensitive
 * information is securely anonymized before being sent to GTM or other third-party analytics services.
 * This function uses SHA-256 hashing to generate irreversible hashes of the provided user data.
 *
 * @param email - The user's email address. It will be normalized (trimmed and lowercased) before hashing.
 * @param phoneNumber - The user's phone number. All whitespace will be removed, and the result will be normalized before hashing.
 * @param address - The user's address object, which includes:
 *   - street_address: The street address of the user. Only this field is hashed.
 *   - zip_code_code: The postal code of the user. This field is normalized but not hashed.
 * @param name - The user's full name. It will be split into first and last names, normalized, and hashed separately.
 * @returns A promise resolving to an object containing hashed user data formatted for GTM enhanced tracking, including:
 *   - sha256_email_address: The hashed email address, or undefined if the input email is empty.
 *   - sha256_phone_number: The hashed phone number, or undefined if the input phone number is empty.
 *   - address: An object containing:
 *     - sha256_first_name: The hashed first name, or undefined if the input name is empty or cannot be split.
 *     - sha256_last_name: The hashed last name, or undefined if the input name is empty or cannot be split.
 *     - sha256_street: The hashed street address, or undefined if the input address is empty.
 *     - postal_code: The normalized postal code, or undefined if the input postal code is empty.
 */
export async function hashUserData({
  email,
  phoneNumber,
  address,
  name,
}: {
  email: string;
  phoneNumber: string;
  address: Cloud$Address;
  name: string;
}): Promise<any> {
  const [firstName, lastName] = splitCustomerName(name);
  const normalizedFirstName = normalizeInput(firstName);
  const normalizedLastName = normalizeInput(lastName);
  const normalizedStreet = normalizeInput(address.street_address);

  return {
    sha256_email_address: await hashSHA256(normalizeInput(email)),
    sha256_phone_number: await hashSHA256(
      normalizeInput(phoneNumber).replace(/\s+/g, ''),
    ),
    address: {
      sha256_first_name: await hashSHA256(normalizedFirstName),
      sha256_last_name: await hashSHA256(normalizedLastName),
      sha256_street: await hashSHA256(normalizedStreet),
      postal_code: normalizeInput(address.zip_code_code),
    },
  };
}
